<template>
  <v-container fluid>
    <empresa/>
  </v-container>
</template>

<script>
import Empresa from '../components/Empresa.vue'
export default {
  components: { Empresa },
  name:'Empresas',
}
</script>